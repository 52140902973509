body {
  background: #006d96;
  font-family: "IBM Plex Sans", sans-serif !important;
}

.slick-prev,
.slick-next {
  font-size: 0 !important;

  background: #004864 !important;
  border: 4px solid #006d96 !important;
  width: 34px !important;
  height: 34px !important;
  border-radius: 50px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
}

.slick-prev:hover,
.slick-next:hover {
  background: #003144 !important;
}

.slick-next:before,
.slick-prev:before {
  background-size: cover !important;
  height: 0.8rem;
  width: 0.8rem;
  content: "" !important;
  display: block;
}

.slick-prev:before {
  background: url('../../components/ui-kit/icons/png/left-arrow.png') no-repeat;
}

.slick-next:before {
  background: url('../../components/ui-kit/icons/png/right-arrow.png') no-repeat;
}

.slick-next {
  right: -15px !important;
}

.slick-prev {
  left: -15px !important;
}

.datePicker>div {
  display: flex;
  height: auto !important;
  width: 100% !important;
}

.datePicker>div>div {
  min-width: 140px;
}

.main-loader {
  position: fixed;
  width: 100%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #003144;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.datePicker h6 {
  color: #fff;
  position: static;
  font-size: 0.875rem;
  min-width: 140px;
  transform: none;
  white-space: unset;
}

.datePicker .MuiFormControl-root {
  flex-direction: column !important;
  align-items: start !important;
}

.after-login {
  padding-top: 2.5rem;
}

.promotion-banner {
  width: 100%;
  position: relative;
  background: url('../../assets/images/welcome-banner.jpg');
  min-height: 280px;
  background-size: cover;
}

@media(max-width:899px) {

  .slick-prev,
  .slick-next {
    width: 30px !important;
    height: 30px !important;
  }

  .after-login {
    padding-top: 0;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

a:-webkit-any-link {
  color: currentColor;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

.text-ellipsis {
  max-width: 100%;
	overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: normal;
}

.line-clamp,
.line-clamp-2,
.line-clamp-3, 
.line-clamp-4, 
.line-clamp-5 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-height: normal;
    line-break: auto;
}

.line-clamp-2 {
    -webkit-line-clamp: 2
}

.line-clamp-3 {
    -webkit-line-clamp: 3
}
.line-clamp-4 {
    -webkit-line-clamp: 4
}
.line-clamp-5 {
    -webkit-line-clamp: 5
}

.cursor-pointer {
  cursor: pointer;
}