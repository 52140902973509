body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #006d96 !important;
  font-size: 16px !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
input:-webkit-autofill {
  box-shadow: 0 0 0 2rem #003144 inset !important;
  -webkit-text-fill-color: #ffffff;
  border-color: #49c7f4;
  caret-color: #ffffff;
  font-size: 14px;
}

input.MuiInputBase-input::placeholder {
  color: #fff;
}

::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #fff;
}

@media(max-width:767px) {
  input:-webkit-autofill {
    font-size: 12px;
  }
}
